<template>
  <v-navigation-drawer
    v-model="dialog"
    app
    temporary
    fixed
    :width="$root.mobile ? '100vw' : '70vw'"
    :hide-overlay="!$root.mobile"
    :right="$root.lang != 'ar'"
    scrollable
  >
    <v-card elevation="0">
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("MF.add") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%">
        <v-stepper v-model="e6" vertical class="elevation-0">
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
            @click="backToStepOne()"
            class="bg-hover-white"
          >
            <span class="font-size-h3">{{ $t("MF.addNew.step1") }}</span>
            <span class="font-size-sm">{{ step1sub }}</span>
          </v-stepper-step>

          <v-stepper-content step="1">
            <div class="row">
              <div
                v-for="item in items"
                :key="item.name"
                class="col-xl-3 col-lg-3 col-md-3 col-sm-3 m-3 bg-hover-gray-200 rounded-lg text-center"
                style="background-color:#f2f7fd; min-height: 150px; display:flex;justify-content: center;align-items: center;"
                @click="selectedEntry(item.id, item.name)"
              >
                <div>
                  <div class="mb-3">
                    <img
                      class="mx-3"
                      height="25px"
                      width="25px"
                      :src="'/media/svg/icons/Doctory/' + item.icon + '.svg'"
                    />
                  </div>
                  <span style="font-size:1rem;" class="">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </v-stepper-content>

          <!-- step #2 -->
          <v-stepper-step :complete="e6 > 2" step="2">
            <span class="font-size-h3">{{ $t("MF.addNew.step2") }}</span>
          </v-stepper-step>

          <v-stepper-content step="2">
            <HistoryForm
              v-if="selectedEntryType == 1"
              :mfid="mfid"
              @backToStepOneAndToast="backAndToast($event)"
            />
            <VitalsForm
              v-if="selectedEntryType == 2"
              :mfid="mfid"
              @backToStepOneAndToast="backAndToast($event)"
            />
            <DiagnosesForm
              v-if="selectedEntryType == 3"
              :mfid="mfid"
              @backToStepOneAndToast="backAndToast($event)"
            />
            <PrescriptionForm
              v-if="selectedEntryType == 4"
              :mfid="mfid"
              @backToStepOneAndToast="backAndToast($event)"
            />
            <FilessForm
              v-if="selectedEntryType == 5"
              :mfid="mfid"
              @backToStepOneAndToast="backAndToast($event)"
            />
            <NotesForm
              v-if="selectedEntryType == 6"
              :mfid="mfid"
              @backToStepOneAndToast="backAndToast($event)"
            />

            <ConsultationForm
              v-if="selectedEntryType == 7"
              :mfid="mfid"
              @backToStepOneAndToast="backAndToast($event)"
            />

            <!-- <v-btn color="primary" :disabled="e6 >= 2" @click="e6 = 3">Next</v-btn> -->
            <!-- <v-btn class="mx-2" text @click="backToStepOne()">Back</v-btn> -->
          </v-stepper-content>

          <!-- <v-stepper-step :complete="e6 > 3" step="3">
            Select an ad format and name ad unit
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-card
              color="grey lighten-1"
              class="mb-12"
              height="200px"
            ></v-card>
            <v-btn color="primary" @click="e6 = 4"> Continue </v-btn>
            <v-btn text> Cancel </v-btn>
          </v-stepper-content> -->

          <!-- <v-stepper-step step="4"> View setup instructions </v-stepper-step>
          <v-stepper-content step="4">
            <v-card
              color="grey lighten-1"
              class="mb-12"
              height="200px"
            ></v-card>
            <v-btn color="primary" @click="e6 = 1"> Continue </v-btn>
            <v-btn text> Cancel </v-btn>
          </v-stepper-content> -->
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
//import ApiService from "@/core/services/api.service";
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import HistoryForm from "./addcomp/historyForm.vue";
import VitalsForm from "./addcomp/vitalsForm.vue";
import NotesForm from "./addcomp/notesForm.vue";
import DiagnosesForm from "./addcomp/diagnosesForm.vue";
import FilessForm from "./addcomp/filesForm.vue";
import PrescriptionForm from "./addcomp/presForm.vue";
import ConsultationForm from "./addcomp/ConsultationForm.vue";

export default {
  name: "Add",
  props: ["mfid", "dialog"],
  components: {
    HistoryForm,
    VitalsForm,
    NotesForm,
    DiagnosesForm,
    FilessForm,
    PrescriptionForm,
    ConsultationForm
  },
  data() {
    return {
      error: "",
      step1sub: this.$t("MF.addNew.step1sub"),
      e6: 1,
      selectedEntryType: 0,
      items: [],
      docitems: [
        { id: 1, name: this.$t("MF.medicalHistoryLabel"), icon: "icons-pc" },
        { id: 2, name: this.$t("MF.vitalslabel"), icon: "icons-monitoring" },
        { id: 3, name: this.$t("MF.diagnosesLabel"), icon: "icons-doctor" },
        { id: 4, name: this.$t("MF.prescriptionLabel"), icon: "icons-pills" },
        { id: 5, name: this.$t("MF.filesLabel"), icon: "icons-file-records" },
        { id: 6, name: this.$t("MF.notesLabel"), icon: "group-700" },
        {
          id: 7,
          name: this.$t("MF.consultationLabel"),
          icon: "icons-nurse"
        }
      ],
      patitems: [
        { id: 1, name: this.$t("MF.medicalHistoryLabel"), icon: "icons-pc" },
        { id: 2, name: this.$t("MF.vitalslabel"), icon: "icons-monitoring" },
        { id: 5, name: this.$t("MF.filesLabel"), icon: "icons-file-records" }
      ]
    };
  },
  beforeMount() {
    if (JSON.parse(localStorage.userInfo).role == 3) {
      this.items = this.docitems;
    } else {
      this.items = this.patitems;
    }
  },
  mounted() {},
  methods: {
    close() {
      this.e6 = 1;
      this.$emit("closeClicked", false);
    },
    selectedEntry(id, title) {
      this.e6 = 2;
      this.step1sub = title;
      this.selectedEntryType = id;
    },
    backToStepOne() {
      this.step1sub = this.$t("MF.addNew.step1sub");
      this.e6 = 1;
    },
    backAndToast(msg) {
      this.toast("b-toaster-top-center", "success", msg);
      this.step1sub = this.$t("MF.addNew.step1sub");
      this.e6 = 1;
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append
      });
    }
  },
  computed: {}
};
</script>
